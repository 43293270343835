var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "quota-empty" },
    [
      _c(
        "iq-title",
        {
          staticClass: "quota-empty__title",
          attrs: { "class-name": "mobileHeader" },
        },
        [
          _c("Icon", {
            attrs: { name: "IconAttentionCircle", width: "25px", height: "25" },
          }),
          _c("span", [_vm._v("Внимание!")]),
        ],
        1
      ),
      _c("iq-title", { attrs: { "class-name": "text" } }, [
        _vm._v(_vm._s(_vm.description)),
      ]),
      !_vm.isExporter
        ? _c(
            "div",
            { staticClass: "quota-empty__block" },
            [
              _c(
                "iq-button",
                {
                  attrs: { icon: "DriverPlus", color: "main-dark" },
                  on: { onClick: _vm.handleAddDay },
                },
                [_vm._v(" Добавить квоту по культуре ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }